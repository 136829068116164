import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import WbTextBox from './textBox'
import { whiteboardConstants } from '@/models/constants'

export type FrameSize = 'custom' | 'wide' | 'a4' | 'letter'

export const frameSizes: Record<FrameSize, { width: number, height: number }> = {
  custom: { width: 400, height: 400 },
  wide: { width: 1280, height: 720 },
  a4: { width: 793.92, height: 1122.24 },
  letter: { width: 816, height: 1056 },
}

interface IWbFrameOptions extends fabric.IRectOptions {
  id?: string
  lock?: boolean
  sortOrder?: number
  children?: string[]
  preventUnlock?: boolean
}

export default class WbFrame extends fabric.Rect implements IWbObject {
  public id: string
  public frameSize: FrameSize = 'custom'
  public children: string[] = []
  public sortOrder: number
  public lock: boolean
  public connectable: boolean
  public type = whiteboardConstants.objectTypes.frame
  private childrenMap = new Map<string, number>()
  private lastLeft = 0
  private lastTop = 0
  public preventUnlock: boolean
  // Use to handle object moving (in undo/redo)
  private titleObj: WbTextBox | null = null
  private canvasZoom = 1
  public movingChangedObjsMap = new Map<string, IWbObject>()

  public editableProps: Record<string, IWbObjectProp> = {
    frameSize: { name: 'Size', type: 'frameSize' },
    color: { name: 'Color', type: 'color' },
    lock: { name: 'Lock', type: 'lock' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public actions: Record<string, IWObjectActions> = {
    rename: { action: 'rename', label: 'Rename', faicon: 'fa-light fa-edit', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    copyLink: { action: 'copyLink', label: 'Copy link', faicon: 'fa-light fa-link', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  static frameSizes: { [key in FrameSize]: string } = {
    custom: 'M19 19V5H5v14h14zM6 18V6h12v12H6z',
    wide: 'M15.05 3 19 8v13H5V3h10.05zM15 4H6v16h12V8.015L17.99 8H15l-.001-4z',
    a4: 'M15.05 3 19 8v13H5V3h10.05zM15 4H6v16h12V8.015L17.99 8H15l-.001-4z',
    letter: 'M15.05 3 19 8v13H5V3h10.05zM15 4H6v16h12V8.015L17.99 8H15l-.001-4z',
  }

  constructor(frameSize: FrameSize, opt?: IWbFrameOptions) {
    super(opt)
    this.id = opt?.id || guid()
    this.children = opt?.children || []
    for (let i = 0; i < this.children.length; i++) {
      this.childrenMap.set(this.children[i], i)
    }
    this.lock = opt?.lock || false
    this.sortOrder = opt?.sortOrder || 0
    this.connectable = true
    this.fill = opt?.fill || 'white'
    // this.shadow = new fabric.Shadow('rgba(0,0,0,0.2) 2px 2px 10px')
    this.stroke = 'grey'
    this.strokeWidth = 1
    this.hasRotatingPoint = false
    this.lockRotation = true
    this.preventUnlock = opt?.preventUnlock || false

    this._setSize(frameSize)
    this.frameSize = frameSize
    this.setLock(this.lock)
    this.resetObjMoving()

    this.stateProperties = this.stateProperties?.concat(['name', 'frameSize', 'lock', 'preventUnlock'])

    this.on('added', () => {
      if (this.canvas) {
        this.canvasZoom = this.canvas.getZoom()
        this.titleObj = new WbTextBox(this.name || '', { fontFamily: 'Arial', fontSize: 18, fill: 'grey', selectable: false, localOnly: true, lock: true, truncate: true })
        this.canvas.add(this.titleObj)
        this.titleObj.setCoords()
        this.titleObj.on('mousedblclick', () => {
          this.fire('rename')
        })
        this.titleObj.on('mousedown', () => {
          this.canvas?.setActiveObject(this)
        })
        this.adjustRelativeToZoom()
      }
    })
    this.on('removed', () => {
      if (this.canvas && this.titleObj) {
        this.canvas.remove(this.titleObj)
        this.titleObj = null
      }
    })

    this.on('scaling', () => {
      const frameSize = this._getFrameSize()
      if (frameSize !== this.frameSize) {
        this.set('frameSize', frameSize)
        this.canvas?.requestRenderAll()
      }
      this.addOrRemoveChildren()
      this.set('width', (this.width || 0) * (this.scaleX || 0))
      this.set('height', (this.height || 0) * (this.scaleY || 0))
      this.set('scaleX', 1)
      this.set('scaleY', 1)
      this.setCoords()
      this.repositionTitle()
    })

    this.on('mousedown', () => {
      this.resetObjMoving()
    })

    this.on('moving', () => {
      const left = (this.left || 0)
      const top = (this.top || 0)
      const objs = this.canvas?.getObjects() as Array<IWbObject>
      this.repositionTitle()

      if (objs && objs.length) {
        objs.forEach((obj) => {
          if (obj.id && this.childrenMap.has(obj.id)) {
            const leftDiff = left - this.lastLeft
            const topDiff = top - this.lastTop
            obj.left = (obj.left || 0) + leftDiff
            obj.top = (obj.top || 0) + topDiff
            obj.setCoords()
            this.movingChangedObjsMap.set(obj.id, obj)
            this.canvas?.fire('object:moving', { target: obj, action: 'via-frame' })
          }
        })
      }
      this.lastLeft = left
      this.lastTop = top
    })

    this.on('mouseup', () => {
      if (this.movingChangedObjsMap.size > 0) {
        for (const itm of this.movingChangedObjsMap) {
          this.canvas?.fire('object:modified', { target: itm[1], ignoreHistory: true })
        }
        this.resetObjMoving()
      }
    })

    this.on('zoom', () => {
      this.adjustRelativeToZoom()
    })
  }

  repositionTitle() {
    if (this.titleObj) {
      this.titleObj.set('left', this.left)
      this.titleObj.set('width', this.width)
      this.titleObj.set('top', (this.top || 0) - (this.titleObj.height || 0) - Math.min(10, 10 / this.canvasZoom))
      this.titleObj.setCoords()
      // this.titleObj.set('height', this.titleObj.hei calcTextHeight())
    }
  }

  adjustRelativeToZoom() {
    const newZoom = this.canvas?.getZoom() || 1
    if (this.canvasZoom !== newZoom) {
      this.canvasZoom = newZoom
      if ((this.width || 0) * newZoom < 100) {
        this.titleObj?.set('visible', false)
      }
      else {
        this.titleObj?.set('visible', true)
        this.titleObj?.set('fontSize', 18 * newZoom)
        this.repositionTitle()
      }
    }
  }

  resetObjMoving(clearMap = true) {
    this.lastLeft = this.left || 0
    this.lastTop = this.top || 0
    if (clearMap) {
      this.movingChangedObjsMap.clear()
    }
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'frameSize':
        this._setSize(value.frameSize)
        this.set('frameSize', value.frameSize)
        break
      case 'color':
        this.set('fill', value.backgroundColor)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'text':
        this.set('name', value.text)
        this.titleObj?.set('text', value.text)
        break
      case 'sortOrder':
        this.set('sortOrder', value.sortOrder)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'frameSize':
        result.frameSize = this.frameSize
        result.path = WbFrame.frameSizes[this.frameSize]
        break
      case 'color':
        result.backgroundColor = this.fill
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'text':
        result.text = this.name
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  addChild(id: string) {
    if (!this.childrenMap.has(id)) {
      this.childrenMap.set(id, this.children.length)
      this.children.push(id)
      this.canvas?.requestRenderAll()
      this.canvas?.fire('object:modified', { target: this, ignoreHistory: true })
    }
  }

  isChild(id: string) {
    return this.childrenMap.has(id)
  }

  removeChild(id: string) {
    if (this.childrenMap.has(id)) {
      const index = this.childrenMap.get(id) as number
      this.children.splice(index, 1)
      this.childrenMap.delete(id)
      this.canvas?.requestRenderAll()
      this.canvas?.fire('object:modified', { target: this, ignoreHistory: true })
    }
  }

  addOrRemoveChildren() {
    const objs = this.canvas?.getObjects() as Array<IWbObject>
    if (objs && objs.length) {
      objs.forEach((obj) => {
        if (obj.id) {
          if (obj.isContainedWithinObject(this)) {
            this.addChild(obj.id)
          }
          else if (this.isChild(obj.id)) {
            this.removeChild(obj.id)
          }
        }
      })
    }
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  private _setSize(frameSize: FrameSize) {
    if (this.frameSize !== frameSize || !this.width || !this.height) {
      this.set('width', frameSizes[frameSize].width)
      this.set('height', frameSizes[frameSize].height)
      this.set('scaleX', 1)
      this.set('scaleY', 1)
      this.setCoords()
      this.addOrRemoveChildren()
    }
  }

  private _getFrameSize(): FrameSize {
    const width = (this.width || 0) * (this.scaleX || 0)
    const height = (this.height || 0) * (this.scaleY || 0)
    let result: FrameSize = 'custom'
    if (width === frameSizes.wide.width && height === frameSizes.wide.height) {
      result = 'wide'
    }
    else if (width === frameSizes.a4.width && height === frameSizes.a4.height) {
      result = 'a4'
    }
    else if (width === frameSizes.letter.width && height === frameSizes.letter.height) {
      result = 'wide'
    }
    return result
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude && Array.isArray(propertiesToInclude) ? propertiesToInclude : []
    props.push('id', 'name', 'frameSize', 'lock', 'sortOrder', 'children', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.frame, object, callback, 'frameSize') as WbFrame
  }
}

const f: any = fabric
f.WbFrame = WbFrame
