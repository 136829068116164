<template>
  <div class="overflow-hidden">
    <div class="flex flex-col h-full overflow-hidden">
      <!-- Header -->
      <div class="flex flex-row items-center p-4 border-b bg-card">
        <div class="flex-grow">
          <div
            class="mb-2 text-4xl font-extrabold leading-none tracking-tight"
            v-text="`${userStore.activeCatalog?.Season} ${t('whiteboard.list.title')}`"
          />
        </div>
        <div class="flex items-center space-x-3">
          <tx-input
            ref="refFilter" v-model="filter" type="text" clearable faicon="fa-light fa-magnifying-glass"
            :placeholder="t('catalogSelection.filter')" autofocus :rounded="true"
          />
          <tx-button type="confirm" width="100px" height="36px" :text="t('general.refresh')" @click="refresh" />
          <tx-dropdown :items="addActions" value-prop="id" display-prop="label" icon-prop="icon" @select="onAddActionSelected">
            <template #button>
              <tx-button type="confirm" width="100px" height="36px" :text="t('general.add')" />
            </template>
          </tx-dropdown>
        </div>
        <div />
        <loader v-if="loading" />
      </div>

      <!-- Recent Whiteboards -->
      <div class="px-4 py-2">
        <div v-if="loading" class="skeleton">
          <whiteboard-card-skeleton v-for="index in 5" :key="index" />
        </div>
        <div v-else class="flex flex-col">
          <div v-if="filteredGroups.recent.length > 0" class="flex">
            <div class="flex-grow pl-4 m-auto font-medium text-lg" v-text="t('whiteboard.list.recent')" />
          </div>
          <div v-if="filteredGroups.recent.length > 0" class="flex flex-row flex-wrap">
            <whiteboard-item
              v-for="item in filteredGroups.recent" :key="item.Id" type="card" :item="item"
              @click="doOpenBoard" @context="(evt, item) => showContextMenu(evt, item)"
            />
          </div>
        </div>
      </div>

      <!-- All Whiteboards -->
      <div class="px-4 py-2 flex-grow flex flex-col w-full overflow-hidden">
        <div class="flex mb-4">
          <div class="flex-grow pl-4 m-auto font-medium text-lg" v-text="t('whiteboard.list.all')" />
        </div>
        <!-- Table -->
        <div class="w-full border border-gray-200 overflow-y-auto rounded-lg shadow-sm">
          <table v-if="!loading" class="table-auto w-full bg-white">
            <thead class="sticky top-0 bg-gray-100 border-b">
              <tr class="border-b">
                <th class="px-4 py-2 text-left font-bold" v-text="t('whiteboard.list.item.name')" />
                <th class="px-4 py-2 text-left font-bold" v-text="t('whiteboard.list.item.owner')" />
                <th class="px-4 py-2 text-left font-bold" v-text="t('whiteboard.list.item.ownerEmail')" />
                <th class="px-4 py-2 text-left font-bold" v-text="t('whiteboard.list.item.createdDate')" />
                <th class="px-4 py-2 text-left font-bold" v-text="t('whiteboard.list.item.updatedDate')" />
                <th class="px-4 py-2 text-left font-bold" v-text="t('whiteboard.list.item.actions')" />
              </tr>
            </thead>
            <tbody>
              <whiteboard-folder-row
                v-for="folder in filteredGroups.my" :key="`folder-${folder.Id}`" :folder="folder"
                @click="doOpenBoard" @context="(evt, item, folder) => showContextMenu(evt, item, folder)"
              />
            </tbody>
          </table>
        </div>
      </div>

      <tx-menu ref="menuRef" :options="menuOptions" @click="onContextMenuOptionClick" />

      <!-- Create/Update Folder Dialog -->
      <create-update-folder-dialog ref="createUpdateFolderDialogRef" :folder-path-separator="whiteboardConstants.folderPathSeparator" @save="onSaveFolder" />

      <!-- Create/Update/Duplicate Whiteboard Dialog -->
      <manage-whiteboard-dialog ref="manageWhiteboardDialogRef" @save="onSaveWhiteboard" />

      <status-bar />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { isArray } from 'lodash-es'
import { onKeyStroke } from '@vueuse/core'
import ReconnectingWebSocket from 'reconnecting-websocket'
import sharedb from 'sharedb/lib/client'
import type { Folder } from './Whiteboard.types'
import * as sBar from '@/store/status'
import StatusBar from '@/shared/components/StatusBar.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import TxMenu from '@/shared/components/TxMenu.vue'
import Loader from '@/shared/components/Loader.vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import CreateUpdateFolderDialog from '@/shared/components/CreateUpdateFolderDialog.vue'
import type { ManageActionType } from '@/modules/whiteboard/components/ManageWhiteboardDialog.vue'
import ManageWhiteboardDialog from '@/modules/whiteboard/components/ManageWhiteboardDialog.vue'
import WhiteboardCardSkeleton from '@/modules/whiteboard/components/WhiteboardCardSkeleton.vue'
import WhiteboardItem from '@/modules/whiteboard/components/WhiteboardItem.vue'
import WhiteboardFolderRow from '@/modules/whiteboard/components/WhiteboardFolderRow.vue'
import type { CreateUpdateWhiteboardModel, UpdateWhiteboardsStatusModel, WhiteboardListModel } from '@/api/t1/model/whiteboardModel'
import { createWhiteboard, getMyWhiteboards, updateWhiteboard, updateWhiteboardFolder, updateWhiteboardsStatus } from '@/api/t1/whiteboard'
import { createContainer, getContainer, getMyContainers, updateContainer } from '@/api/t1/container'
import type { ContainerItemDetails } from '@/api/t1/model/containerModel'
import { useUserStore } from '@/store/userData'
import { useNotificationStore } from '@/store/notification'
import { containerTypeConstants, privileges, whiteboardConstants } from '@/models/constants'
import Whiteboard from '@/models/whiteboard'
import appConfig from '@/services/appConfig'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const confirmDialog = useConfirmDialog()

const loading = ref(false)
const whiteboards = ref<Whiteboard[]>([])
const createUpdateFolderDialogRef = ref<InstanceType<typeof CreateUpdateFolderDialog>>()
const manageWhiteboardDialogRef = ref<InstanceType<typeof ManageWhiteboardDialog>>()
const menuRef = ref<InstanceType<typeof TxMenu>>()
const contextMenuItem = ref()
const filter = ref('')
const recentWhiteboardsContainer = ref<ContainerItemDetails>()
const refFilter = ref<InstanceType<typeof TxInput> | null>(null)
const tempFolderIds = new Set<string>()

const addActions: Array<IToolbarActionButton<string>> = [
  { id: 'addFolder', label: t('whiteboard.list.actions.addFolder'), icon: 'fa-light fa-folder' },
  { id: 'addBoard', label: t('whiteboard.list.actions.addBoard'), icon: 'fa-light fa-chalkboard' },
]

const menuOptions = computed<Array<IContextMenuItem>>(() => {
  const isFolder = contextMenuItem.value?.hasOwnProperty('Items')
  const isBlank = contextMenuItem.value?.Name === t('general.blankValue')
  let isEditAllow = true
  if (contextMenuItem.value && userStore.userProfile.AccountDetails.AccountTypeId !== 1) {
    if (isFolder) {
      const folder = contextMenuItem.value as Folder
      isEditAllow = getAllItemsForFolder(folder).every(w => w.CreatedBy === userStore.userProfile.Id)
    }
    else {
      isEditAllow = (contextMenuItem.value as Whiteboard).CreatedBy === userStore.userProfile.Id
    }
  }

  return [{
    key: 'duplicate',
    label: t('whiteboard.list.actions.duplicate'),
    icon: 'fa-light fa-plus',
    disabled: false,
    visible: !isBlank && !isFolder && userStore.userProfile.isValidPrivilege(privileges.whiteboard.create),
  }, {
    key: 'edit',
    label: t('whiteboard.list.actions.edit'),
    icon: 'fa-light fa-edit',
    disabled: false,
    visible: isEditAllow && !isBlank && ((isFolder && userStore.userProfile.isValidPrivilege(privileges.whiteboard.updateFolder))
    || (!isFolder && userStore.userProfile.isValidPrivilege(privileges.whiteboard.update))),
  }, {
    key: 'delete',
    label: t('whiteboard.list.actions.delete'),
    icon: 'fa-light fa-trash-can',
    disabled: false,
    visible: isEditAllow && !isBlank && userStore.userProfile.isValidPrivilege(privileges.whiteboard.updateStatus),
  }]
})

onKeyStroke('/', (e) => {
  if ((e.target as HTMLElement)?.nodeName.toLowerCase() !== 'input') {
    e.preventDefault()
    refFilter.value?.focus()
  }
}, { target: document })

async function refresh() {
  if (userStore.activeCatalog) {
    loading.value = true
    await userStore.doLoadData(['Whiteboards'])

    const myWhiteboards = await (userStore.userProfile.AccountDetails.AccountTypeId === 1
      ? appConfig.DB!.whiteboards.where('CatalogCode').equals(userStore.activeCatalog.CatalogCode)
      : appConfig.DB!.whiteboards.where({ CatalogCode: userStore.activeCatalog.CatalogCode, CreatedBy: userStore.userProfile.Id })).toArray()

    const sharedWhiteboards: Whiteboard[] = []
    if (userStore.userProfile.AccountDetails.AccountTypeId !== 1) {
      await getMyWhiteboards(userStore.activeCatalog.CatalogCode, 'shared')
        .then((resp) => {
          if (resp.data.length) {
            resp.data.forEach(item => sharedWhiteboards.push(new Whiteboard(userStore.activeCatalog!.CatalogCode, item)))
          }
        })
        .catch(e => console.error('Unable to fetch shared whiteboards', e))
    }

    tempFolderIds.clear()

    whiteboards.value = myWhiteboards.concat(sharedWhiteboards)
      .filter(w => w.Status > 0)
      .sort((a, b) => b.CreatedDate.getTime() - a.CreatedDate.getTime())

    sBar.setItemValue('ttlBoards', whiteboards.value.length.toLocaleString())

    loading.value = false
  }
}

const parentFoldersMap = computed(() => new Map<string, string>(whiteboards.value.filter(itm => utils.isValidStringValue(itm.FolderId)).map(itm => [itm.FolderId!, itm.FolderName!])))

const parentFolders = computed(() => Array.from(parentFoldersMap.value.entries()).map(x => ({ key: x[0], label: x[1] } as IKeyLabel<string>)))

const filteredWhiteboards = computed<Whiteboard[]>(() => {
  const filterValue = filter.value.toLowerCase()
  return whiteboards.value.filter(itm => itm.Name.toLowerCase().includes(filterValue)
    || itm.FolderName?.toLowerCase().includes(filterValue)
    || itm.CreatedByName.toLowerCase().includes(filterValue)
    || itm.CreatedByEmail.toLowerCase().includes(filterValue))
})

const filteredGroups = computed(() => {
  const res = {
    recent: [] as Whiteboard[],
    my: [] as Folder[],
  }
  let recentBoards = new Set<number>()
  if (recentWhiteboardsContainer.value) {
    const recent = utils.tryParse(recentWhiteboardsContainer.value.Value)
    if (recent && isArray(recent)) {
      recentBoards = new Set<number>(recent)
    }
  }

  const folderMap = new Map<string, Folder>()

  filteredWhiteboards.value.forEach((itm) => {
    if (itm.CreatedByUserName === userStore.currentUsername) {
      addTreeNode(res.my, itm, folderMap)
    }
    else {
      addTreeNode(res.my, itm, folderMap)
    }
    if (recentBoards.has(itm.Id)) { res.recent.push(itm) }
  })
  return res
})

function addTreeNode(folders: Folder[], whiteboard: Whiteboard, folderMap: Map<string, Folder>) {
  const folderPath = whiteboard.FolderName?.split('|') || [t('general.blankValue')]
  let parentFolder: Folder | undefined

  folderPath.forEach((folderName, index) => {
    const folderId = folderPath.slice(0, index + 1).join('|') // Unique ID based on path

    if (!folderMap.has(folderId)) {
      const newFolder: Folder = {
        Id: folderId,
        Name: folderName || t('general.blankValue'),
        FolderId: whiteboard.FolderId,
        ParentId: parentFolder?.Id,
        Children: [],
        Items: [],
      }

      folderMap.set(folderId, newFolder)

      if (parentFolder) {
        parentFolder.Children.push(newFolder)
      }
      else {
        folders.push(newFolder)
      }
    }

    parentFolder = folderMap.get(folderId)!
  })

  // Add whiteboard to the correct folder
  if (parentFolder && utils.isValidStringValue(whiteboard.Name)) {
    parentFolder.Items.push(whiteboard)
  }
}

function onAddActionSelected(sel: IToolbarActionButton<string>) {
  if (sel.id === 'addFolder') {
    createUpdateFolderDialogRef.value?.showDialog(parentFolders.value.map(x => x.label))
  }
  else if (sel.id === 'addBoard') {
    manageWhiteboardDialogRef.value?.showDialog(parentFolders.value, 'create')
  }
}

function doOpenBoard(item: Whiteboard) {
  // Update the list of recent boards
  if (recentWhiteboardsContainer.value) {
    let recent = utils.tryParse(recentWhiteboardsContainer.value.Value)
    if (recent && isArray(recent)) {
      recent.unshift(item.Id)
      if (recent.length > 5) {
        recent.pop()
      }
    }
    else {
      recent = [item.Id]
    }
    const recentWhiteboardContainerName = `${whiteboardConstants.recentWhiteboardsContainerName}-${userStore.userProfile.Id}`
    updateContainer(userStore.userProfile.Id, recentWhiteboardsContainer.value.Id, recentWhiteboardContainerName, JSON.stringify(recent), userStore.activeCatalog!.CatalogCode)
  }
  const params = { ...route.params, whiteboardId: item.Id }
  router.push({ name: 'WhiteboardDetails', params })
}

function showContextMenu(event: MouseEvent, item?: Whiteboard, folder?: Folder) {
  event.preventDefault()
  if (!item && !folder) {
    contextMenuItem.value = undefined
    menuRef.value?.doClose()
    return
  }
  const menuItem = item ?? folder
  contextMenuItem.value = menuItem
  menuRef.value?.openMenu(event, menuItem)
}

function getAllItemsForFolder(folder: Folder): Whiteboard[] {
  // Start with the items in the current folder
  let items: Whiteboard[] = [...folder.Items]

  // Recursively add items from child folders
  for (const child of folder.Children) {
    items = items.concat(getAllItemsForFolder(child))
  }

  return items
}

async function onContextMenuOptionClick(option: IContextMenuItem, targetItem: any) {
  contextMenuItem.value = targetItem
  if (option.key === 'duplicate') {
    manageWhiteboardDialogRef.value?.showDialog(parentFolders.value, 'duplicate', targetItem)
  }
  else if (option.key === 'edit') {
    if (targetItem.hasOwnProperty('Items')) {
      const folder = targetItem as Folder
      if (folder?.FolderId) {
        createUpdateFolderDialogRef.value?.showDialog(parentFolders.value.map(x => x.label), { folderId: folder.FolderId, folderName: folder.Name, parentFolder: folder.ParentId ?? '' })
      }
    }
    else {
      manageWhiteboardDialogRef.value?.showDialog(parentFolders.value, 'update', targetItem)
    }
  }
  else if (option.key === 'delete') {
    let payload: UpdateWhiteboardsStatusModel[] = []
    const title = t('general.alert')
    let message = ''
    if (targetItem.hasOwnProperty('Items')) {
      const items = getAllItemsForFolder(targetItem)
      payload = items.map(i => ({ Id: i.Id, Status: 0 }))

      message = t('whiteboard.deleteWhiteboards.folderWarning')
    }
    else {
      const item = targetItem as Whiteboard
      payload.push({ Id: item.Id, Status: 0 })
      message = t('whiteboard.deleteWhiteboards.whiteboardWarning')
    }

    await confirmDialog(title, message, [], async () => {
      if (payload.length) {
        await updateWhiteboardsStatus(userStore.activeCatalog!.CatalogCode, payload)
        await userStore.doLoadData(['Whiteboards'])
      }
      refresh()
    })
  }
}

function onSaveFolder(name: string, parentFolder: string, folderId?: string) {
  let folderName = name
  if (utils.isValidStringValue(parentFolder)) {
    folderName = `${parentFolder}${whiteboardConstants.folderPathSeparator}${name}`
  }

  if (folderId && utils.isValidStringValue(folderId)) {
    if (tempFolderIds.has(folderId)) {
      const whiteboard = whiteboards.value.find(w => w.FolderId === folderId)
      if (whiteboard) {
        whiteboard.FolderName = folderName
        whiteboard.UpdatedDate = new Date()
        whiteboard.UpdatedBy = userStore.userProfile.Id
      }
      createUpdateFolderDialogRef.value?.closeDialog()
    }
    else if (userStore.activeCatalog) {
      updateWhiteboardFolder(userStore.activeCatalog.CatalogCode, folderId, folderName)
        .then(async () => {
          await userStore.doLoadData(['Whiteboards'])
          notificationStore.addNotification({ message: t('whiteboard.manageDialog.success'), type: 'Info', actions: ['ShowDetails', 'Support'] })
          refresh()
        })
        .catch((err) => {
          console.error('Failed to create/update board', err)
          notificationStore.addNotification({ message: t('whiteboard.manageDialog.error'), type: 'Alert', actions: ['ShowDetails', 'Support'], details: utils.getErrorMessage(err) })
        })
        .finally(() => {
          createUpdateFolderDialogRef.value?.closeDialog()
        })
    }
  }
  else if (userStore.activeCatalog) {
    const folderId = utils.randomId()
    tempFolderIds.add(folderId)
    whiteboards.value.push(new Whiteboard(userStore.activeCatalog.CatalogCode, {
      Id: whiteboards.value.length,
      Name: '',
      FolderId: folderId,
      FolderName: folderName,
      Status: 1,
      CreatedBy: userStore.userProfile.Id,
      CreatedByEmail: userStore.userProfile.Email,
      CreatedByName: userStore.userProfile.fullName,
      CreatedByUserName: userStore.currentUsername,
      CreatedDate: new Date(),
      UpdatedBy: userStore.userProfile.Id,
      UpdatedDate: new Date(),
    } as WhiteboardListModel))

    createUpdateFolderDialogRef.value?.closeDialog()
  }
}

function onSaveWhiteboard(folder: string, name: string, actionType: ManageActionType, whiteboard?: Whiteboard) {
  if (userStore.activeCatalog && name.length > 0) {
    const folderName = parentFoldersMap.value.get(folder)
    const payload: CreateUpdateWhiteboardModel = {
      Name: name,
      FolderId: folder,
      FolderName: folderName,
    }
    let promise: Promise<any> | null = null

    if (whiteboard) {
      if (actionType === 'update') {
        promise = updateWhiteboard(userStore.activeCatalog.CatalogCode, whiteboard.Id, payload)
      }
      else if (actionType === 'duplicate') {
        promise = new Promise((resolve, reject) => {
          try {
            const ws = new ReconnectingWebSocket(`${appConfig.ShareDbUrl}/server?t=${encodeURIComponent(localStorage.getItem('tk') || '')}`)
            // eslint-disable-next-line ts/ban-ts-comment
            // @ts-expect-error
            const dbConn = new sharedb.Connection(ws)
            let dbDoc = dbConn.get('boards', `${appConfig.T1Env}${whiteboard.Id}`)
            dbDoc.fetch((err) => {
              if (err) {
                dbConn.close()
                ws.close()
                return reject(new Error('Unable to fetch source whiteboard'))
              }

              // source data
              const data = dbDoc.data || { version: 0 }

              // Create on T1
              createWhiteboard(userStore.activeCatalog!.CatalogCode, payload).then((res) => {
                const newBoardId = `${appConfig.T1Env}${res.data.Id}`
                dbDoc = dbConn.get('boards', newBoardId)
                data.version = 0
                dbDoc.create(data, (err) => {
                  dbConn.close()
                  ws.close()
                  if (err) {
                    return reject(new Error('Unable to create new whiteboard'))
                  }
                  return resolve(true)
                })
              }).catch((err) => {
                return reject(err)
              })
            })
          }
          catch (error) {
            reject(error)
          }
        })
      }
    }
    else {
      promise = createWhiteboard(userStore.activeCatalog.CatalogCode, payload)
    }

    if (promise) {
      promise.then(async () => {
        await userStore.doLoadData(['Whiteboards'])
        notificationStore.addNotification({ message: t('whiteboard.manageDialog.success'), type: 'Info', actions: ['ShowDetails', 'Support'] })
        refresh()
      })
        .catch((err) => {
          console.error('Failed to create/update board', err)
          notificationStore.addNotification({ message: t('whiteboard.manageDialog.error'), type: 'Alert', actions: ['ShowDetails', 'Support'], details: utils.getErrorMessage(err) })
        })
        .finally(() => {
          manageWhiteboardDialogRef.value?.closeDialog()
        })
    }
    else {
      manageWhiteboardDialogRef.value?.closeDialog()
    }
  }
}

onBeforeMount(async () => {
  sBar.setItems([
    { id: 'ttlBoards', label: t('status.totalBoards'), value: '0', icon: '' },
  ])
  refresh()
  // Find the container that has the list of my recent boards , container name will be appended with the userId to keep it unique for all users
  const recentWhiteboardContainerName = `${whiteboardConstants.recentWhiteboardsContainerName}-${userStore.userProfile.Id}`
  const containersList = await utils.tryAsync(getMyContainers(userStore.activeCatalog!.CatalogCode, containerTypeConstants.recentWhiteboards))
  if (containersList.success) {
    const container = containersList.result.data.find(f => f.ContainerName === recentWhiteboardContainerName)
    if (!container) {
      // If container doesnt exist, create it
      const res = await utils.tryAsync(createContainer(userStore.userProfile.Id, recentWhiteboardContainerName, '[]', userStore.activeCatalog!.CatalogCode, containerTypeConstants.recentWhiteboards))
      if (res.success) { recentWhiteboardsContainer.value = res.result.data }
    }
    else {
      // If container exists, load it
      const res = await utils.tryAsync(getContainer(container.Id))
      if (res.success) { recentWhiteboardsContainer.value = res.result.data }
    }
  }
})
</script>
