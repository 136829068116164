<template>
  <tx-dropdown
    id="modNoti" ref="refNotificationDropdown" faicon="fa-light fa-bell" button-type="nav" class="mx-auto" :ping="isAnyNewNotification" :count="visibleNotifications.length + notifications.length" :max-height="550"
    @close="onClose()" @open="onOpen()"
  >
    <div class="w-[330px] h-[550px] flex flex-col overflow-hidden rounded">
      <div class="flex p-2 mb-2 bg-primary text-on-primary">
        <div class="flex-1 text-sm uppercase">
          {{ t('general.notifications', notifications.length) }}
        </div>
        <font-awesome-icon class="w-4 h-4 cursor-pointer" icon="fa-light fa-xmark" @click="refNotificationDropdown?.closeDropdown" />
      </div>
      <!-- TODO:we need to merge with tx-tabs if possible -->
      <tx-notification-tabs v-model="activeTab" class="h-14 w-full pb-1 px-2" :tabs="Object.values(detailsTabs)" :tab-info="tabInfo" />
      <div v-if="activeTab === 'updatesToMyRequest'" class="pt-2 px-2 overflow-auto">
        <div
          v-for="notification in updatesToMyRequestNotifications" :key="notification.Id"
          :class="{ 'bg-grey-light': !notification.Read }" class="flex flex-row py-3 mb-1 cursor-pointer border-2" @click="onRequestNotificationClick(notification)"
        >
          <span v-tooltip="{ text: notification.Read ? t('notificationDropdown.markAsUnread') : t('notificationDropdown.markAsRead'), theme: { placement: 'right' } }">
            <font-awesome-icon
              class="w-5 h-5 mx-2"
              :icon="notification.Read ? 'fa-light fa-envelope-open' : 'fa-light fa-envelope' "
              @click.stop="UpdateNotificationReadStatus(notification)"
            />
          </span>
          <div class="flex flex-col grow">
            <!-- header -->
            <div class="flex flex-row ">
              <div class="w-[66.5%]">
                <div class="text-md font-semibold">
                  {{ notification.Title }}
                </div>
                <div v-if="currentRoute === 'CatalogsList'" class="text-xs">
                  {{ notification.CatalogName }}
                </div>
              </div>
              <div v-tooltip="{ text: utils.formatDateTime(notification.UpdatedDate) }" class="text-xs text-disabled">
                <use-time-ago v-slot="{ timeAgo }" :time="notification.UpdatedDate">
                  {{ timeAgo }}
                </use-time-ago>
              </div>
            </div>
            <!-- SUbheader -->
            <!-- body -->
            <div class="pt-1" v-html="notification.Description" />
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'requestsPendingMyAction'" class="pt-2 px-2 overflow-auto">
        <div
          v-for="notification in requestPendingMyActionNotifications" :key="notification.Id"
          :class="{ 'bg-grey-light': !notification.Read }" class="flex flex-row py-3 mb-1 cursor-pointer border-2" @click="onRequestNotificationClick(notification)"
        >
          <span v-tooltip="{ text: notification.Read ? t('notificationDropdown.markAsUnread') : t('notificationDropdown.markAsRead'), theme: { placement: 'right' } }">
            <font-awesome-icon
              class="w-5 h-5 mx-2"
              :icon="notification.Read ? 'fa-light fa-envelope-open' : 'fa-light fa-envelope' "
              @click.stop="UpdateNotificationReadStatus(notification)"
            />
          </span>
          <div class="flex flex-col grow">
            <!-- header -->
            <div class="flex flex-row ">
              <div class="w-[69%]">
                <div class="text-md font-semibold">
                  {{ notification.Title }}
                </div>
                <div v-if="currentRoute === 'CatalogsList'" class="text-xs">
                  {{ notification.CatalogName }}
                </div>
              </div>
              <div v-tooltip="{ text: notification.UpdatedDate.toDateString() }" class="text-xs text-disabled">
                <use-time-ago v-slot="{ timeAgo }" :time="notification.UpdatedDate">
                  {{ timeAgo }}
                </use-time-ago>
              </div>
            </div>
            <!-- SUbheader -->
            <!-- body -->
            <div class="pt-1" v-html="notification.Description" />
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'others'" class="flex flex-col overflow-y-auto grow">
        <div
          v-for="notification in notifications"
          :key="notification.id" class="flex py-2"
          :class="{ 'cursor-pointer': notification.status === 'done' }" @click="onNotificationClick(notification)"
        >
          <font-awesome-icon
            class="w-5 h-5 p-2 mx-2 rounded bg-primary text-on-primary"
            :class="{ 'bg-warn': notification.status === 'fail', 'bg-success': notification.status === 'done' || notification.status === 'complete' }"
            :icon="notification.status === 'done' || notification.status === 'complete' ? 'fa-light fa-cloud-arrow-down' : notification.status === 'fail' ? 'fa-light fa-file-xmark' : 'fa-light fa-file-export'"
          />
          <div class="flex flex-col px-2 grow">
            <div v-if="notification.type === 'Articles'">
              {{ t('notificationDropdown.importArticles') }}
            </div>
            <div v-else-if="notification.type === 'editSizes'">
              {{ t('notificationDropdown.updateSizes') }}
            </div>
            <div v-else-if="notification.type === 'exportPPT'">
              {{ t('notificationDropdown.generatePPT') }}
            </div>
            <div v-else-if="notification.type === 'assortArticles'">
              {{ t('notificationDropdown.assortArticles') }}
            </div>
            <div v-else-if="notification.type === 'updateViaImport'">
              {{ t('notificationDropdown.updateViaImport') }}
            </div>
            <div v-else-if="notification.type === 'updateViaImportModel'">
              {{ t('notificationDropdown.updateViaImportModel') }}
            </div>
            <div v-else-if="notification.type === 'importByLicensee'">
              {{ t('notificationDropdown.importByLicensee') }}
            </div>
            <div v-else-if="notification.type === 'setSizesByLicenseeModelNumber'">
              {{ t('notificationDropdown.setSizesByLicenseeModelNumber') }}
            </div>
            <div v-else-if="notification.type === 'setPricesByLicenseeModelNumber'">
              {{ t('notificationDropdown.setPricesByLicenseeModelNumber') }}
            </div>
            <div v-else-if="notification.type === 'editRequestBulk'">
              {{ t('notificationDropdown.editRequestBulk') }}
            </div>
            <div v-else>
              {{ t('notificationDropdown.generatePdf') }}
            </div>
            <div v-if="notification.status === 'new' || notification.status === 'processing' || notification.status === 'inprocess'" class="text-xs">
              <p class="text-xs">
                {{ t('notificationDropdown.processing') }}
              </p>
              <tx-indeterminate-progress />
            </div>
            <div v-else-if="notification.status === 'done' && (notification.type === 'exportPPT' || notification.type === 'exportPDF')" class="text-xs">
              {{ t('notificationDropdown.download') }}
            </div>
            <div v-else-if="notification.status === 'complete' && (notification.type === 'Articles' || notification.type === 'editSizes' || notification.type === 'assortArticles' || notification.type === 'importArticles' || notification.type === 'updateViaImport' || notification.type === 'updateViaImportModel' || notification.type === 'importByLicensee' || notification.type === 'setSizesByLicenseeModelNumber' || notification.type === 'setPricesByLicenseeModelNumber' || notification.type === 'editRequestBulk')" class="flex flex-row flex-wrap h-4 grow">
              <p class="flex-1 text-xs">
                {{ t('notificationDropdown.completed') }}
              </p>
              <div v-tooltip="{ text: t('general.refresh'), theme: { placement: 'left', width: '100' } }">
                <font-awesome-icon class="w-3 h-3 p-1 mx-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-refresh" @click="onDataRefresh(notification)" />
              </div>
            </div>
            <div v-else-if="notification.status === 'fail'" class="flex flex-row flex-wrap h-4 grow">
              <p class="flex-1 text-xs text-red-600">
                {{ t('notificationDropdown.failed') }}
              </p>
              <div v-tooltip="{ text: t('general.details'), theme: { placement: 'left', width: '4000' } }">
                <font-awesome-icon class="w-3 h-3 p-1 mx-1 text-red-600 cursor-pointer hover:text-red-800" icon="fa-light fa-exclamation-triangle" @click="showErrorList(notification.id)" />
              </div>
            </div>
            <div class="text-xs text-disabled">
              <use-time-ago v-slot="{ timeAgo }" :time="notification.date">
                {{ timeAgo }}
              </use-time-ago>
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dropdown>
</template>

<script lang="ts" setup>
import { UseTimeAgo } from '@vueuse/components'
import axios from 'axios'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxIndeterminateProgress from '@/shared/components/TxIndeterminateProgress.vue'
import { getStatus } from '@/api/fileServer/generate'
import utils from '@/services/utils'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import TxNotificationTabs from '@/shared/components/TxNotificationTab.vue'
import type NotificationModel from '@/models/notificationModel'
import { requestConstants } from '@/models/constants'
import { markNotificationReadOrUnread } from '@/api/t1/notification'
import { useNotificationStore } from '@/store/notification'

export interface INotificationItem {
  id: string
  status: string
  type: 'exportPPT' | 'Articles' | 'exportPDF' | 'editSizes' | 'assortArticles' | 'importArticles' | 'updateViaImport' | 'updateViaImportModel' | 'importByLicensee' | 'setSizesByLicenseeModelNumber' | 'setPricesByLicenseeModelNumber' | 'editRequestBulk'
  downloadUrl: string
  date: Date
}

const emit = defineEmits<{
  (e: 'refresh', notification: INotificationItem): void
  (e: 'showErrorInformation', notificationId: string): void
  (e: 'showRequestDetails', articleId: number, requestId: number | undefined): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const notificationStore = useNotificationStore()
const refNotificationDropdown = ref<InstanceType<typeof TxDropdown>>()
const detailsTabs = {
  updatesToMyRequest: { key: 'updatesToMyRequest', label: 'notificationDropdown.tabs.updatesToMyRequest', visible: true },
  requestsPendingMyAction: { key: 'requestsPendingMyAction', label: 'notificationDropdown.tabs.requestsPendingMyAction', visible: true },
  others: { key: 'others', label: 'notificationDropdown.tabs.others', visible: true },
}
const activeTab = ref(detailsTabs.updatesToMyRequest.key)
let intervalId: number | undefined
let requestIntervalId: number | undefined
let jobsSub: Subscription | undefined
let reqJobsSub: Subscription | undefined
const notifications = ref<INotificationItem[]>([])
const requestNotifications = ref<NotificationModel[]>([])
const isAnyNewNotification = ref(false)
const currentRoute = computed(() => {
  return route.name
})
const visibleNotifications = computed(() => {
  if (currentRoute.value !== 'CatalogsList' && userStore.activeCatalog) {
    return requestNotifications.value.filter(request => request.CatalogCode === userStore.activeCatalog!.CatalogCode)
  }
  else {
    return requestNotifications.value
  }
})
const updatesToMyRequestNotifications = computed(() => {
  return visibleNotifications.value.filter(not => not.NotificationTab === 'Information')
})

const requestPendingMyActionNotifications = computed(() => {
  return visibleNotifications.value.filter(not => not.NotificationTab === 'Action')
})

const tabInfo = computed(() => {
  const info = { updatesToMyRequest: 0, requestsPendingMyAction: 0, others: 0 }
  info.updatesToMyRequest = updatesToMyRequestNotifications.value.length
  info.requestsPendingMyAction = requestPendingMyActionNotifications.value.length
  info.others = notifications.value.length
  return info
})

function UpdateNotificationReadStatus(notification: NotificationModel) {
  markNotificationReadOrUnread(notification.Id, notification.Read).then(() => {
    const notificationToBeUpdated = requestNotifications.value.find(not => not.Id === notification.Id)
    if (notificationToBeUpdated) {
      notificationToBeUpdated.Read = !notification.Read
    }
  }).catch ((error) => {
    console.error(error)
    notificationStore.addNotification({ message: t('notificationDropdown.readStatusFailed', { read: notification.Read ? 'Unread' : 'Read' }), type: 'Alert' })
  })
}
async function onRequestNotificationClick(notification: NotificationModel) {
  const currentRouteName = currentRoute.value?.toString()
  let articleId = notification.ReferenceArticleId
  let objectId: string | undefined
  const requestId = notification.TargetObjectId
  if (notification.RequestType === requestConstants.requestTypes.AddColorway.key || notification.RequestType === requestConstants.requestTypes.SimilarStyle.key || notification.RequestType === requestConstants.requestTypes.CarryoverArticle.key) {
    articleId = notification.TargetObjectId
    objectId = notification.TargetObject
  }

  if (currentRouteName === 'CatalogsList') {
    router.push({ name: 'OpenCatalog', params: { catalogCode: notification.CatalogCode }, state: {
      articleId,
      objectId,
      requestId,
    } })
    if (!notification.Read) {
      UpdateNotificationReadStatus(notification)
    }
  }
  else {
    if (requestId) {
      const requestArticle = await appConfig.DB!.getRequestArticleById(userStore.activeCatalog!, requestId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
      if (requestArticle) {
        if (articleId && currentRouteName) {
          if (router.hasRoute(`${currentRouteName}ArticleDetails`)) {
            const newParams: { articleId: string, objectId?: string } = { articleId: articleId.toString(), objectId: undefined }
            if (objectId) {
              newParams.objectId = objectId
            }
            const mergedParams = { ...router.currentRoute.value.params, ...newParams }

            router.push({ name: `${currentRouteName}ArticleDetails`, params: mergedParams, query: { ...router.currentRoute.value.query }, state: {
              requestId,
            } })
          }
          else {
            emit('showRequestDetails', articleId, requestId)
          }
        }
        if (!notification.Read) {
          UpdateNotificationReadStatus(notification)
        }
      }
      else {
        notificationStore.addNotification({ message: t('notificationDropdown.requestNotFound'), type: 'Alert' })
      }
    }
    else {
      console.warn('error in notification data')
    }
  }
}
function onNotificationClick(notification: INotificationItem) {
  if (utils.isValidStringValue(notification.downloadUrl)) {
    axios({
      url: notification.downloadUrl,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileName = notification.type === 'exportPPT' ? `${notification.id}.pptx` : `${notification.id}.pdf`
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName) // or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
}

function initLiveQuery() {
  if (jobsSub && !jobsSub.closed) { jobsSub.unsubscribe() }
  if (userStore.activeCatalog && userStore.userProfile) {
    const observable = liveQuery(async () => await appConfig.DB!.jobs.where({ CatalogCode: userStore.activeCatalog?.CatalogCode, UserId: userStore.userProfile.Id }).toArray())
    jobsSub = observable.subscribe((jobs) => {
      notifications.value = []
      if (jobs.length) {
        jobs.sort((a, b) => b.CreatedDate.getTime() - a.CreatedDate.getTime())
          .forEach((job) => {
            if (!isAnyNewNotification.value && job.CreatedDate > userStore.notificationsLastVisited && (job.Status === 'fail' || job.Status === 'done' || job.Status === 'complete')) {
              isAnyNewNotification.value = true
              userStore.setNotificationsLastVisited()
            }
            notifications.value.push({
              id: job.Id,
              status: job.Status,
              type: job.Type,
              downloadUrl: job.DownloadUrl ? job.DownloadUrl : '',
              date: job.CreatedDate,
            })
          })
      }
    })
  }
}
function initRequestLiveQuery() {
  if (reqJobsSub && !reqJobsSub.closed) { reqJobsSub.unsubscribe() }
  if (userStore.userProfile) {
    const observable = liveQuery(async () => await appConfig.DB!.notifications.where({ UserId: userStore.userProfile.Id }).toArray())
    reqJobsSub = observable.subscribe((reqNotifications) => {
      requestNotifications.value = []
      if (reqNotifications.length) {
        reqNotifications.sort((a, b) => b.UpdatedDate.getTime() - a.UpdatedDate.getTime())
        if (!isAnyNewNotification.value && new Date(reqNotifications[0].UpdatedDate) > userStore.notificationsLastVisited) {
          isAnyNewNotification.value = true
          userStore.setNotificationsLastVisited()
        }
        requestNotifications.value = reqNotifications
      }
    })
  }
}
function onClose() {
  isAnyNewNotification.value = false
  userStore.setLastNotificationSelectedTab(activeTab.value)
}

function onOpen() {
  load()
  isAnyNewNotification.value = false
  activeTab.value = userStore.lastNotificationSelectedTab !== '' ? userStore.lastNotificationSelectedTab : requestNotifications.value.length && requestNotifications.value[0].NotificationTab === 'Action' ? detailsTabs.requestsPendingMyAction.key : detailsTabs.updatesToMyRequest.key
}

async function onDataRefresh(notification: INotificationItem) {
  emit('refresh', notification)
}

function showErrorList(notificationId: string) {
  emit('showErrorInformation', notificationId)
}
function load() {
  if (requestIntervalId) { clearInterval(requestIntervalId) }
  appConfig.DB?.getMyNotification(userStore.userProfile.Id)
  requestIntervalId = setInterval(() => {
    appConfig.DB?.getMyNotification(userStore.userProfile.Id)
  }, appConfig.requestNotificationInterval ? appConfig.requestNotificationInterval : 600000)
}
watch(() => [userStore.activeCatalog, userStore.userProfile], initLiveQuery)
watch(() => [userStore.userProfile], initRequestLiveQuery)

onMounted(async () => {
  // fetch request Notifications, store then in DB and store the date and make live query call api after every configured minutes
  if (!userStore.userProfile) {
    await userStore.setUserProfile()
  }
  load()
  initRequestLiveQuery()
  initLiveQuery()
  intervalId = setInterval(() => {
    if (userStore.activeCatalog && notifications.value && notifications.value.length) {
      notifications.value.forEach((notification) => {
        if (notification.status === 'new' || notification.status === 'processing' || notification.status === 'inprocess') {
          getStatus(notification.id, notification.type)
            .then((res) => {
              if (res.data) {
                appConfig.DB!.jobs.update([userStore.activeCatalog!.CatalogCode, notification.id], {
                  Status: (notification.type === 'Articles' || notification.type === 'editSizes' || notification.type === 'assortArticles' || notification.type === 'importArticles' || notification.type === 'updateViaImport' || notification.type === 'updateViaImportModel' || notification.type === 'importByLicensee' || notification.type === 'setSizesByLicenseeModelNumber' || notification.type === 'setPricesByLicenseeModelNumber' || notification.type === 'editRequestBulk') ? res.data.toString().toLowerCase() : res.data.status,
                  DownloadUrl: res.data.url ? res.data.url : '',
                })
              }
            })
            .catch((e) => {
              console.error(e)
            })
        }
      })
    }
  }, 10000)
})

onUnmounted(() => {
  if (jobsSub && !jobsSub.closed) { jobsSub.unsubscribe() }
  if (intervalId) { clearInterval(intervalId) }
  if (reqJobsSub && !reqJobsSub.closed) { reqJobsSub.unsubscribe() }
  if (requestIntervalId) { clearInterval(requestIntervalId) }
  requestNotifications.value = []
})
</script>
